import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { useDispatch } from 'react-redux'
import { commonConstants } from '_constants'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { NotFound } from 'pages/NotFound'
import schema from '_schema/submittedShareForm'

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  bold: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  row: {
    marginTop: 10,
    marginBottom: 10,
  },
})

export const View = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    false,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const classes = useStyles()

  return isFetching ? (
    <Paper>
      <Loader />
    </Paper>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Paper>
      <div className={classes.root}>
        <div className={classes.row}>
          <span className={classes.bold}>Imię:</span> {resource.applicantName}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Nazwisko:</span>{' '}
          {resource.applicantSurname}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Telefon kontaktowy:</span>{' '}
          {resource.applicantPhone}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Email:</span> {resource.applicantEmail}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>W imieniu:</span>
        </div>
        <div className={classes.row}>
          {resource.inNameOf.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Charakter użycia:</span>{' '}
          {resource.useCharacter.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Pola eksploatacji:</span>
        </div>
        <div className={classes.row}>
          {resource.exploitation.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Szczegóły:</span>
        </div>
        <div className={classes.row}>
          {resource.details.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Departament:</span>{' '}
          {resource.department.title}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Wykaz plików:</span>
        </div>
        <div className={classes.row}>
          {resource.files.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"').replaceAll('&#039;', "'")}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Udostępnianie plików:</span>
        </div>
        <div className={classes.row}>
          {resource.emails.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"').replaceAll('&#64;', '@')}
                <br />
              </div>
            ) : null
          )}
        </div>
        <div className={classes.row}>
          <span className={classes.bold}>Zgody:</span>
        </div>
        <div className={classes.row}>
          {resource.agreements.split('\n').map((row, index) =>
            row !== '' ? (
              <div key={index}>
                {row.replaceAll('&#34;', '"')}
                <br />
              </div>
            ) : null
          )}
        </div>
      </div>
    </Paper>
  )
}
