import home from 'pages/Home/routes'
import admins from 'pages/Admins/routes'
import adminGroups from 'pages/AdminGroups/routes'
import loginEntries from 'pages/LoginEntries/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import settings from 'pages/Settings/routes'
import profile from 'pages/Profile/routes'
import pages from 'pages/Pages/routes'
import translatorEntries from 'pages/TranslatorEntries/routes'
import config from 'pages/Config/routes'
import artists from 'pages/Artists/routes'
import exhibits from 'pages/Exhibits/routes'
import exhibitions from 'pages/Exhibitions/routes'
import collections from 'pages/Collections/routes'
import events from 'pages/Events/routes'
import eventTypes from 'pages/EventTypes/routes'
import news from 'pages/News/routes'
import newsTypes from 'pages/NewsTypes/routes'
import footerMenuItems from 'pages/FooterMenuItems/routes'
import homeSlides from 'pages/HomeSlides/routes'
import branches from 'pages/Branches/routes'
import importEntries from 'pages/ImportEntries/routes'
import resources from 'pages/Resources/routes'
import magazines from 'pages/Magazines/routes'
import pressArticles from 'pages/PressArticles/routes'
import formsets from 'pages/Formsets/routes'
import managerMedia from 'pages/ManagerMedia/routes'
import eventCycle from 'pages/EventCycles/routes'
import publishingHouseAuthors from 'pages/PublishingHouseAuthors/routes'
import publishingHouseSeries from 'pages/PublishingHouseSeries/routes'
import publishingHousePublications from 'pages/PublishingHousePublications/routes'
import newsletterCategory from 'pages/NewsletterCategory/routes'
import newsletterConfig from 'pages/ConfigNewsletter/routes'
import jobs from 'pages/Job/routes'
import archiveArticles from 'pages/ArchiveArticles/routes'
import archiveCategories from 'pages/ArchiveCategories/routes'
import archiveCycles from 'pages/ArchiveCycles/routes'
import clonerQueueItems from 'pages/ClonerQueueItems/routes'
import shareFormCinemaCategories from 'pages/ShareFormCinemaCategories/routes'
import shareFormExhibitionCategories from 'pages/ShareFormExhibitionCategories/routes'
import shareFormPromotionCategories from 'pages/ShareFormPromotionCategories/routes'
import shareFormPublisherCategories from 'pages/ShareFormPublisherCategories/routes'
import shareFormScientificCategories from 'pages/ShareFormScientificCategories/routes'
import shareFormTheaterCategories from 'pages/ShareFormTheaterCategories/routes'
import shareFormPafdChoices from 'pages/ShareFormPafdChoices/routes'
import shareFormArchiveChoices from 'pages/ShareFormArchiveChoices/routes'
import submittedShareForms from 'pages/SubmittedShareForms/routes'

const routes = () => [
  ...getArray(home()),
  ...getArray(pages()),
  ...getArray(artists()),
  ...getArray(exhibits()),
  ...getArray(exhibitions()),
  ...getArray(collections()),
  ...getArray(events()),
  ...getArray(eventTypes()),
  ...getArray(news()),
  ...getArray(newsTypes()),
  ...getArray(footerMenuItems()),
  ...getArray(homeSlides()),
  ...getArray(branches()),
  ...getArray(admins()),
  ...getArray(adminGroups()),
  ...getArray(loginEntries()),
  ...getArray(registryEntries()),
  ...getArray(translatorEntries()),
  ...getArray(settings()),
  ...getArray(profile()),
  ...getArray(config()),
  ...getArray(importEntries()),
  ...getArray(resources()),
  ...getArray(magazines()),
  ...getArray(pressArticles()),
  ...getArray(formsets()),
  ...getArray(managerMedia()),
  ...getArray(eventCycle()),
  ...getArray(publishingHouseAuthors()),
  ...getArray(publishingHouseSeries()),
  ...getArray(publishingHousePublications()),
  ...getArray(newsletterCategory()),
  ...getArray(newsletterConfig()),
  ...getArray(jobs()),
  ...getArray(archiveArticles()),
  ...getArray(archiveCategories()),
  ...getArray(archiveCycles()),
  ...getArray(clonerQueueItems()),
  ...getArray(shareFormCinemaCategories()),
  ...getArray(shareFormExhibitionCategories()),
  ...getArray(shareFormPromotionCategories()),
  ...getArray(shareFormPublisherCategories()),
  ...getArray(shareFormScientificCategories()),
  ...getArray(shareFormTheaterCategories()),
  ...getArray(shareFormPafdChoices()),
  ...getArray(shareFormArchiveChoices()),
  ...getArray(submittedShareForms()),
]

const getArray = routes => Object.values(routes)

export default routes
