const schema = {
  endpoint: '/api/submitted_share_forms',
  access: 'submittedShareForm',
  resource: {
    definition: '#SubmittedShareForm',
    properties: {
      applicantName: {},
      applicantSurname: {},
      applicantPhone: {},
      applicantEmail: {},
      useCharacter: {},
      exploitation: {},
      details: {},
      department: {},
      files: {},
      emails: {},
      agreements: {},
    },
  },
}

export default schema
